import { Description as MdDescriptionIcon, Folder as MdFolderIcon } from '@material-ui/icons';
import { globalHistory } from '@reach/router';
import { Link } from 'gatsby';
import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { strMethods } from '../helpers';

const DirectoryIndex = props => {
	let currPathname = globalHistory.location.pathname;
	const displayName = strMethods.slugToDisplayName(props.pageContext.slug);
	const children = props.pageContext.meta.children;
	// Compile listings - subdirectories and files
	let listings = [];
	listings = listings.concat(
		children.dirs.map(function (d) {
			return {
				path: '/' + d.relativePath,
				display: '/' + d.name,
				isDir: true,
			};
		})
	);
	listings = listings.concat(
		children.md.map(function (m) {
			return {
				path: m.fields.slug,
				display: m.parent.name,
				isDir: false,
			};
		})
	);
	return (
		<Layout slug={props.pageContext.slug}>
			<SEO title={`${props.pageContext.slug}`} />
			<h1>Generated Index Page - {`${displayName}`}</h1>
			<div className="directoryListingWrapper">
				<div className="directoryListingRow">
					<Link to={`${currPathname + '/../'}`}>
						<MdFolderIcon />
						.. (Go Up a Directory)
					</Link>
				</div>
				{listings.map(listing => {
					return (
						<div className="directoryListingRow" key={`${listing.path + new Date().getTime()}`}>
							{listing.isDir ? <MdFolderIcon /> : <MdDescriptionIcon />}
							<Link to={`${listing.path}`}>{listing.display}</Link>
						</div>
					);
				})}
			</div>
		</Layout>
	);
};

export default DirectoryIndex;
